import { createContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { getUserFullProfile, getUserProfile } from "../utils/preTokenApi";
import { useDispatch } from "react-redux";
import { setSessionUserProfileData } from "../redux/reducers/LoginUserReducer";

const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {
  const cookies = new Cookies();

  const [accessToken, setAccessToken] = useState("");
  const [isInitPreLoading, setIsInitPreLoading] = useState(true); //Initial Loader
  const [isPreLoading, setIsPreLoading] = useState(false); // Preloading Other

  /*  */
  const [activeLink, setActiveLink] = useState("");

  const [foodProfileId, setFoodProfileId] = useState("");
  const [userProfileData, setUserProfileData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [loginUserData, setLoginUserData] = useState("")

  const { REACT_APP_CLIENT_ID } = process.env;

  const headers = {
    access_token: accessToken,
    user_timezone: "Asia/Calcutta",
    "Content-Type": "application/json",
    client_id: REACT_APP_CLIENT_ID,
  };

  // useEffect(() => {
  //   if(accessToken){
  //     const decodedToken = jwtDecode(accessToken);
  //   }
  // }, [accessToken]);

  const dispatch = useDispatch();
  // const { SessionUserProfileData } = useSelector((state) => state.LoginUserReducer);

  const getProfile = async () => {
    try {
      const getUserProfileResponse = await getUserProfile();
      setUserProfileData(getUserProfileResponse.data?.profile);
      setLoginUserData(getUserProfileResponse.data)
      dispatch(setSessionUserProfileData(getUserProfileResponse.data?.profile)); //Redux  Test
      return true;
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );

      if (error.response && error.response.status === 403) {
        return false;
      }

      throw error;
    } finally {
    }
  };

  const getFullProfile = async () => {
    try {
      const getFullProfileResponse = await getUserFullProfile();
      
      setFoodProfileId(getFullProfileResponse?.data?.cc_profile?.id);
      setUserRole(getFullProfileResponse?.data?.role_id);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
    } finally {
    }
  };

  useEffect(() => {
    if (new Cookies().get("access_token")) {
      setAccessToken(new Cookies().get("access_token"));
    }
  }, []);

  const ShowNotification = (type, message) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  useEffect(() => {
    return async () => {
      setTimeout(() => {
        setIsInitPreLoading(false);
      }, 2000);
    };
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      getProfile()
        .then((profileSuccess) => {
          if (profileSuccess) {
            getFullProfile();
          }
        })
        .catch((error) => {
          cookies.remove("access_token");
          localStorage.clear();
          window.location.href = "/login";
          console.error("Error during getProfile:", error);
        });

      // getProfile();
      // getFullProfile()
    }
  }, [accessToken]);

  const [themeColorCode, setThemeColorCode] = useState(null);

  useEffect(() => {
    if (themeColorCode) {
      const root = document.documentElement;
      localStorage.setItem("dh-theme-code", themeColorCode);
      root.style.setProperty("--primary-base-theme-color", themeColorCode);
      root.style.setProperty("--primary-blue-gradeient-start", themeColorCode);
    }
  }, [themeColorCode]);

  useEffect(() => {
    let currentTheme = localStorage.getItem("dh-theme-code");
    if (currentTheme) {
      setThemeColorCode(currentTheme);
    } else {
      setThemeColorCode("#2f7aaf");
    }
  }, []);

  const handleSetDefault = () =>{
    setThemeColorCode("#2f7aaf");
  }

  const [initRoles, setInitRoles] = useState(true);
  const [rolesData, setRolesData] = useState([]);

  const [initManageUsers, setInitManageUsers] = useState(true);
  const [manageUsersList, setManageUsersList] = useState([]);
  const [manageUsersTotalCount, setManageUsersTotalCount] = useState(10);
  const [manageUsersParam, setManageUsersParam] = useState({
    limit: 10,
    offset: 0,
    orderBy: "id",
    order: "DESC",
    search: ""
  });


  return (
    <MainContext.Provider
      value={{
        foodProfileId,
        userProfileData,
        ShowNotification,
        accessToken,
        setAccessToken,

        isInitPreLoading,
        isPreLoading,
        setIsPreLoading,
        setIsInitPreLoading,
        activeLink,
        setActiveLink,

        headers,
        userRole,
        setUserRole,
        themeColorCode,
        setThemeColorCode,
        handleSetDefault,
        initRoles, setInitRoles,
        rolesData, setRolesData,

        initManageUsers, setInitManageUsers,
        manageUsersList, setManageUsersList,
        manageUsersParam, setManageUsersParam,
        manageUsersTotalCount, setManageUsersTotalCount,
        loginUserData
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;
